/**
 * OutFocus
 * Detect if element Out of focus by clicking anywhere else in the page
 * and setting timer to do specific functions
 *
 * @param element JQuery Element
 * @param fn      function
 */
function OutFocus(element, fn,time=1000) {
    var timer;
    jQuery(window).on('click', function (e) {
        if (!element.is(e.target) && element.has(e.target).length === 0) {
            timer = setTimeout(function () {
                fn();
            }, time);
        } else {
            clearTimeout(timer);
        }
    })
}

