/**
 * Setting a cookie with expire days nummber
 * @param c_name
 * @param value
 * @param exdays
 */
function setCookie(c_name, value, exdays)
{
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
}

/**
 * Setting a cookie with expire Hours
 * @param c_name
 * @param value
 * @param exHours
 */
function setCookieTime(c_name, value, exHours){
    var exdate = new Date();
    exdate.setHours(exdate.getHours() + exHours);
    var c_value = escape(value) + ((exHours == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
}

/**
 * Get cookie Value
 * @param c_name
 * @returns {*}
 */
function getCookie(c_name)
{
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++)
    {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == c_name)
        {
            return unescape(y);
        }
    }
    return false;
}

/**
 * Delete Cookie
 * @param c_name
 */
function clearCookie(c_name){
    setCookie(c_name,"",-1);
}