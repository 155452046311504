/*
 * STMNT Framework
 * 2018-2019
 *
 */

(function ($) {
  "use strict";

  let lang = $("body").data("lang");

  // Window Global letiables
  let WinEventsOptions = {
    scrollDelay: 10,
    resizeDelay: 350,
  };
  TweenMax.ticker.useRAF(false);
  TweenMax.ticker.fps(60);

  //https://github.com/pdroll/windowevents
  let winEvents = new WindowEvents(WinEventsOptions);

  let setMobile = function (desktop, tablet, mobile) {
    let value = desktop;
    let windowsize = $(window).width();

    $(window).resize(function () {
      let windowsize = $(window).width();
    });

    if (windowsize <= 767) {
      value = mobile;
    }

    if (windowsize > 767 && windowsize < 992) {
      value = tablet;
    }

    if (windowsize >= 992) {
      value = desktop;
    }

    return value;
  };

  let is_touch = function () {
    return !!(
      "ontouchstart" in window || // works on most browsers
      navigator.maxTouchPoints
    ); // works on IE10/11 and Surface
  };

  let STMNT = {
    init: function () {
      this.initHeader();
      this.initMobileMenu();
      this.initContent();
      this.initArchive();
      this.initReset();
      this.modal_functions();
      this.news_overview();
      this.zoom_image_effect();
      this.get_uw_consent();
      this.end();

      (function () {
        function init($) {
          jQuery(window).on("elementor/frontend/init", function () {
            // Wait for elementor to be initialized.
            // Then override elementorFrontend.storage.get and elementorFrontend.storage.set with empty functions,
            // to block Elementor from writing or reading anything there.
            if (
              elementorFrontend &&
              !elementorFrontend.isEditMode() &&
              !elementorFrontend.isWPPreviewMode()
            ) {
              elementorFrontend.storage.get = function () {};
              elementorFrontend.storage.set = function () {};

              if (window.sessionStorage) {
                window.sessionStorage.removeItem("elementor");
              }

              if (window.localStorage) {
                window.localStorage.removeItem("elementor");
              }
            }
          });
        }

        var waitForJQuery = setInterval(function () {
          if (typeof jQuery != "undefined") {
            init(jQuery);
            clearInterval(waitForJQuery);
          }
        }, 10);
      })();
    },

    initHeader: function () {
      if (this.debug) console.log("Execute: Header");

      /* Search Box */
      $(".js-header-search").click(function (e) {
        if (!$(this).hasClass("is-active")) e.preventDefault();
        $(this).addClass("is-active");
      });

      /* Header Navigation */
      let Nav = $(".js-header-nav");
      let NavDepth1 = Nav.find(".sub-menu-container");
      let NavDepth2 = NavDepth1.find(".side-sub-menu");

      let Submenu_bg = $(".js-header-nav-bg");
      let Nav_pointer = $(".js-header-pointer");
      let menuAnimation = new TimelineMax({ paused: true, reversed: false });

      let MenuTimer;

      let NavClose = function () {
        TweenMax.to(Submenu_bg, 0.5, { height: 0 });
        TweenMax.to(Nav_pointer, 0.5, { opacity: 0, top: 0 });

        menuAnimation.clear();
        menuAnimation.to(NavDepth1, 0.2, { autoAlpha: 0 });
        menuAnimation.to(NavDepth2, 0.2, { autoAlpha: 0 });
        menuAnimation.play();

        Nav.find(".is-open").removeClass("is-open");
        Nav.find(".menu-item-has-children").removeClass("is-sub-open");

        return true;
      };

      Nav.on("mouseout", function () {
        MenuTimer = setTimeout(NavClose, 800);
      });
      Nav.on("mouseover", function () {
        clearTimeout(MenuTimer);
      });

      OutFocus(
        $(".element-nav"),
        function () {
          NavClose();
        },
        0
      );

      Nav.on("mouseenter touchstart", ">li>a", function () {
        let element_pointer_settings = {
          height: "5px",
          width: $(this).width() + 20 + "px",
          left: $(this).position().left - 10 + "px",
          top: -5,
          opacity: 1,
        };

        let Parent = $(this).parent();
        let Submenu = Parent.find(".sub-menu-container");

        let fullHeight = Submenu.css("height", "auto").outerHeight();

        if (Parent.hasClass("is-open")) return;

        TweenMax.to(Submenu_bg, 0.5, { height: fullHeight });

        TweenMax.to(Nav_pointer, 0.5, element_pointer_settings);

        menuAnimation.clear();
        menuAnimation.to(NavDepth1, 0.2, { autoAlpha: 0 });
        menuAnimation.fromTo(Submenu, 0.2, { autoAlpha: 0 }, { autoAlpha: 1 });
        menuAnimation.play();

        Parent.siblings().removeClass("is-open");
        Parent.addClass("is-open");
      });

      NavDepth1.on(
        "mouseenter touchstart",
        ".menu-item-has-children > a",
        function () {
          let Parent = $(this).parent();
          let Submenu = Parent.find(".side-sub-menu");

          if (Parent.hasClass("is-sub-open")) return;

          menuAnimation.clear();
          menuAnimation.to(NavDepth2, 0.2, { autoAlpha: 0 });
          menuAnimation.fromTo(
            Submenu,
            0.2,
            { autoAlpha: 0 },
            { autoAlpha: 1 }
          );
          menuAnimation.play();

          Parent.siblings().removeClass("is-sub-open");
          Parent.addClass("is-sub-open");
        }
      );

      // Header Elements
      let Header = $("#js-header");

      let HeaderFix = new TimelineMax({ paused: true, reversed: false });

      // Fixing Header
      Header.scrollToFixed({
        spacerClass: "fix-header-spacer",
        zIndex: 2000,
      });

      HeaderFix.fromTo(
        Header.find(".element-top"),
        0.5,
        { autoAlpha: 1 },
        { autoAlpha: 0 },
        "elementClean"
      )

        .fromTo(
          Header.find(".logo-container svg"),
          0.5,
          { height: setMobile("92px", "60px", "50px"), y: 0 },
          {
            height: "50px",
            y: setMobile(8, 8, 0),
          },
          "elementClean"
        )

        .fromTo(
          Header,
          0.5,
          { height: setMobile("150px", "100px", "50px") },
          { height: setMobile("80px", "80px", "50px") },
          "elementClean"
        )

        .fromTo(
          Header.find("svg .logo-e-1"),
          0.2,
          { autoAlpha: 1, x: 0 },
          { autoAlpha: 0, x: 0 },
          "hideall-=0.5"
        )
        .fromTo(
          Header.find("svg .logo-e-2"),
          0.2,
          { autoAlpha: 1, x: 0 },
          { autoAlpha: 0, x: 0 },
          "hideall-=0.5"
        )

        .fromTo(
          $(".sub-menu-container"),
          0.6,
          { top: setMobile("150px", "100px", "50px") },
          { top: "80px" },
          "elementClean"
        )
        .fromTo(
          $(".js-header-nav-bg"),
          0.6,
          { top: setMobile("150px", "100px", "50px") },
          { top: "80px" },
          "elementClean"
        );

      // Resizing Header on down scroll
      winEvents.on("scroll", function (scrollData) {
        if (scrollData.scrollTop >= 200) {
          $("body").addClass(function () {
            HeaderFix.play();
            return "header-fixed";
          });
        } else {
          $("body").removeClass(function () {
            if (!HeaderFix.reversed()) {
              HeaderFix.reverse();
            }
            return "header-fixed";
          });
        }
      });

      if (is_touch()) {
        Nav.find(".menu-item-has-children>a").each(function () {
          let link = $(this).attr("href");
          let submenu = $(this).parent().find("ul:first");

          submenu.prepend(
            '<li class="menu-item"><a href="' + link + '">Übersicht</a></li>'
          );
        });

        Nav.find(".menu-item-has-children>a").click(function (e) {
          e.preventDefault();

          $(this).trigger("mouseenter");

          return false;
        });
      }
    },

    initMobileMenu: function () {
      let mobile_nav = $("nav.mobile-navigation");
      let mobile_toggle = $(".js-mobile-nav");

      mobile_toggle.click(function () {
        mobile_nav.slideToggle(400);
      });

      // Show Submenu
      mobile_nav.find(".menu-item-has-children>a").click(function (e) {
        e.preventDefault();
        let submenu = $(this).parent().find(">.sub-menu");
        submenu.slideToggle(400);
        return false;
      });

      mobile_nav.find(".menu-item-has-children>a").each(function () {
        let link = $(this).attr("href");
        let submenu = $(this).parent().find(">.sub-menu");

        submenu.prepend(
          '<li class="menu-item"><a href="' + link + '">Übersicht</a></li>'
        );
      });
    },
    initContent: function () {
      $(".cb-slider").each(function () {
        let slider = $(this).find(".js-slider");
        let sliderCaption = $(this).find(".js-slider-caption");
        let captions = $(this).find("[data-caption]");

        slider.slick({
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          adaptiveHeight: true,
        });

        slider.on("afterChange init", function (event, slick, current) {
          let captionText = captions.eq(current).data("caption");
          let currentText = current + 1 + "/" + captions.length;

          sliderCaption.find(".js-slider-title").html(captionText);
          sliderCaption.find(".js-slider-counter").html(currentText);
        });
      });

      $(".js-accordion").each(function () {
        let Aco = $(this);

        Aco.find(".js-accordion-trigger").click(function (event) {
          event.preventDefault();

          let container = $(this).closest(".accordion-item");
          let content = container.find(".item-content");
          container.stop(true, false).toggleClass("is-closed");
          content.stop(true, false).slideToggle(400);
          return;
        });
      });
    },

    initArchive: function () {
      function AjaxRequest(data) {
        let Container = $(".js-archive-posts");

        $.ajax({
          url: admin.ajax_url,
          type: "post",
          data: data,
          dataType: "JSON",

          success: function (result) {
            TweenMax.to(Container, 0.3, {
              autoAlpha: 0,
              y: -20,
              onComplete: function () {
                Container.html(result.html);
                TweenMax.to(Container, 0.3, { autoAlpha: 1, y: 0 });
                PageNav();
              },
            });
          },
        });
      }

      $(document).on("click", ".js-archive-filter a", function (event) {
        event.preventDefault();

        let $_class = $(this).parent().attr("class");

        let $_ID = $_class.match(/\d+/)[0]; // Matching the first Number in the Classes

        let data = {
          action: "AjaxArchive",
          id: $_ID,
        };
        // Switch Class
        $(this)
          .parent()
          .addClass("current-cat")
          .siblings()
          .removeClass("current-cat");

        AjaxRequest(data);
      });

      $(document).on("click", ".js-page-nav a", function (event) {
        event.preventDefault();

        let $_category = $(".js-archive-filter .current-cat").attr("class");

        let PageId = $(this).find(".page-id");

        let $_page = PageId.length ? parseInt(PageId.html()) : 1; // $(this).attr('href').split("/page/")[1].split("/")[0]

        let $_ID = $_category.match(/\d+/)[0];
        let data = {
          action: "AjaxArchive",
          page: $_page,
          id: $_ID,
        };

        AjaxRequest(data);
      });

      $(document).on("mouseover", ".js-page-nav a", function () {
        PageNav($(this));
      });

      $(document).on("mouseleave", ".js-page-nav a", function () {
        // PageNav();
      });

      // Pagination
      function PageNav($_hover) {
        let Nav = $(".js-page-nav");

        if (!Nav.find(".nav-links").length) return;

        let Gray = Nav.find(".screen-reader-text");
        let Blue = Nav.find(".current");

        $_hover = typeof $_hover !== "undefined" ? $_hover : Blue;

        let element_pointer_settings = {
          left: $_hover.position().left + 5 + "px",
        };
        TweenMax.to(Gray, 0.3, element_pointer_settings);
      }

      PageNav();
    },

    initReset: function () {
      OutFocus($(".js-header-search"), function () {
        $(".js-header-search").removeClass("is-active");
      });
    },

    get_uw_consent: async function () {
      let uw_consent_popup = $(".js--uw-consent-popup");

      // Trigger popup
      $(".js--empty-uw-container").on("click", async function (event) {
        event.preventDefault();
        $(uw_consent_popup).toggleClass("show");
      });

      // Close popup
      $(".js--uw-consent-close").on("click", async function (event) {
        event.preventDefault();
        $(uw_consent_popup).removeClass("show");
      });

      // Accept consent
      $(".js--uw-consent-accept").on("click", async function (event) {
        event.preventDefault();
        sessionStorage.setItem("uw-custom-consent", "YES");
        (function (d) {
          var s = d.createElement("script");
          s.setAttribute("data-account", "ydlaNBhdq7");
          s.setAttribute("src", "https://cdn.userway.org/widget.js");
          (d.body || d.head).appendChild(s);
        })(document);
        $(this).html("Wird geladen ...");
        for (let consent_state of current_consent_states) {
          consent_state.querySelector("span").innerHTML = "Ja";
        }
        setTimeout(async function () {
          let footer_menu = document.getElementById(
            "menu-footer-copyrights-menu"
          );
          let trigger_intval = setInterval(async function () {
            if ($("#userwayAccessibilityIcon").length > 0) {
              clearInterval(trigger_intval);
              $(".js--empty-uw-container").addClass("hidden");
              $(uw_consent_popup).removeClass("show");
              setTimeout(async function () {
                $("#userwayAccessibilityIcon").trigger("click");
              }, 100);
            }
          }, 500);
          let menu_item_uw_consent =
            footer_menu.querySelector(".menu-item-3185");
          menu_item_uw_consent.style.display = "inline-block";
        }, 601);
      });

      // Unset consent
      $(".js--uw-consent-unset").on("click", async function (event) {
        event.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        $(this).html("Wird geladen ...");
        for (let consent_state of current_consent_states) {
          consent_state.querySelector("span").innerHTML = "Nein";
        }
        setTimeout(async function () {
          location.reload();
        }, 301);
      });

      let current_consent_states = document.querySelectorAll(
        ".js--current-consent-state"
      );
      for (let consent_state of current_consent_states) {
        consent_state.querySelector("span").innerHTML = isset_uw_consent
          ? "Ja"
          : "Nein";
      }
    },

    modal_functions: async function () {
      let modal_opener = $(".js--modal-opener");
      let modal_closer = $(".js--modal-closer");

      await $(modal_opener).on("click", async function () {
        $(this).parent().find(".js--modal").addClass("show");
        $("body").css("overflow", "hidden");
      });

      await $(modal_closer).on("click", async function () {
        $(this).parent().removeClass("show");
        $("body").css("overflow", "initial");
      });
    },

    news_overview: async function () {
      let filter_button = ".js--news-filter-button";

      $(filter_button).on("click", async function (event) {
        // Event prevent default
        event.preventDefault();

        let url = new URL(window.location.href);
        let search_params = url.searchParams;
        let cat = $(this).attr("data-id");
        let base_button_url = $(this).attr("data-url");

        let base_url = new URL(base_button_url);
        let base_url_search_params = base_url.searchParams;

        if (cat == "" || cat == undefined) {
          cat = "all";
        }
        search_params.set("cat", cat);
        base_url_search_params.set("cat", cat);
        await $(filter_button).each(async (index, button) => {
          $(button).removeClass("active");
        });
        $(this).addClass("active");

        window.location = base_url;
      });
    },

    zoom_image_effect: async function () {
      let zoom_image_containers = $(".js--zoom-images");
      if ($(zoom_image_containers).length > 0) {
        for (let zoom_image_container of zoom_image_containers) {
          $(zoom_image_container)
            .find("img")
            .each(async function (index, image) {
              $(image).addClass("has-zoom");
              let source = $(image).attr("src");
              let image_text = $(image).attr("alt");
              let parent = image.parentNode;
              let parent_node = parent.nodeName.toLowerCase();

              // Only append if there is no link as parent
              if (parent_node != "a") {
                $(image).wrap(
                  '<a aria-label="Bild ' +
                    image_text +
                    '" title="Bild ' +
                    image_text +
                    '" href="' +
                    source +
                    '"></a>'
                );
              }
            });
        }
      }
    },

    end: function () {
      console.log(
        "%cLoaded STMNT Theme successfully",
        'background-color: #323A45; color: lightgreen; font-family: "Arial"; font-size: 14px;'
      );
    },
  };

  STMNT.init();
})(jQuery);
