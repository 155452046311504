(function ($) {


    var MapCls = '.js-train-map';
    var MapEle = $(MapCls);
    if (!MapEle.length)
        return;


    function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

        if (arguments.length === 2) {
            x = y = 0;
            w = ctx.canvas.width;
            h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5;
        offsetY = typeof offsetY === "number" ? offsetY : 0.5;

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0;
        if (offsetY < 0) offsetY = 0;
        if (offsetX > 1) offsetX = 1;
        if (offsetY > 1) offsetY = 1;

        var iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r,   // new prop. width
            nh = ih * r,   // new prop. height
            cx, cy, cw, ch, ar = 1;

        // decide which gap to fill    
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
    }


    var D3MC = function(){

        // Privat Vars
        
        var CanvasContainer = null;
        var Canvas          = null;
        var CTX             = null;
        var Elements        = {};
        var Poitns = [
            {x:250,y:240,title:"Welcome",text:"this is a text"},
            {x:270,y:230,title:"Welcome",text:"this is a text"},
            {x:280,y:220,title:"Welcome",text:"this is a text"},
        ]

        var CreateCanvas = function(){

            // Detect the container Size 
            CanvasContainer = {width:MapEle.width(),height:MapEle.height()}

            Canvas = d3.select(MapCls).append("canvas")
                         .attr("id"     , "js-canvas")
                         .attr("width"  , CanvasContainer.width)
                         .attr("height" , CanvasContainer.height);

            CTX = Canvas.node().getContext("2d");



            // Adding Events
            var ZoomingEvent = d3.zoom().scaleExtent([1, 8]).on("zoom", EventZoom);
            Canvas.call(ZoomingEvent);

        };


        var EventZoom = function(){
            
            var transform = d3.event.transform;
            CTX.save();
            CTX.clearRect(0, 0, CanvasContainer.width, CanvasContainer.height);
            CTX.translate(transform.x, transform.y);
            CTX.scale(transform.k, transform.k);
            DrowRestart();
            CTX.restore();
           
        };

        var Clear = function(){
            if(MapEle.has('canvas'))
                MapEle.empty();
        }


        var DrowStart = function(){

            var imageBox = {width:3000,height:2717};

            Elements.BG = new Image();
            Elements.BG.src = 'http://zps.local/wp-content/themes/zps-stmnt/assets/dist/images/map.jpg';
            Elements.BG.width = CanvasContainer.width;
            Elements.BG.height = ((imageBox.height*CanvasContainer.width)/imageBox.width);

            // Calculate the half of the canvas and center the element
            Elements.BG.offsetY = (CanvasContainer.height / 2 )-(Elements.BG.height / 2 )  ;
            
            Elements.BG.onload = function() { CTX.drawImage(Elements.BG, 0, Elements.BG.offsetY,Elements.BG.width,Elements.BG.height);  };
            Elements.svg = [];

            $.each(Poitns,function(index,item){
                Elements.svg[index] = new Image();
                Elements.svg[index].src = 'http://zps.local/wp-content/themes/zps-stmnt/assets/dist/images/marker.svg';
                Elements.svg[index].onload = function(){ CTX.drawImage(Elements.svg[index],item.x,item.y,16,16) };
                Elements.svg[index].on('click',function(){})
            });

        };


        var DrowRestart = function(){
           CTX.drawImage(Elements.BG, 0, Elements.BG.offsetY,Elements.BG.width,Elements.BG.height);
           $.each(Poitns,function(index,item){
            CTX.drawImage(Elements.svg[index],item.x,item.y,16,16  );
           });
        };

       

        Clear();
        CreateCanvas();
        DrowStart();


    };


    var MC = new D3MC; 



})(jQuery);
